
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ProjectUser } from '../../types/entities';

@Component
export default class ProjectUserSelectComponent extends Vue {
	@PropSync('value', { type: Array }) valueSync!: ProjectUser[];
	@Prop() validationErrors!: any[];
	@Prop() users!: ProjectUser[];
	@Prop() labelIcon!: string;
	@Prop() labelText!: string;
	@Prop() placeholderText!: string;

	errorMessages(user: ProjectUser): string[] {
		const errors: string[] = [];
		let overallPercentage = 0;
		const userModel = this.valueSync.find(x => x.id === user.id);
		if (userModel) {
			if (!userModel.workPercentage) {
				errors.push('Verplicht');
				return errors;
			}
			this.valueSync.forEach(x => {
				if (x.workPercentage) {
					overallPercentage += x.workPercentage;
				}
			});
			(overallPercentage !== 100) && errors.push('Som mag niet hoger zijn dan 100');
		}
		return errors;
	}

	getUserWorkPercentage(user: ProjectUser): any {
		const userModel = this.valueSync.find(x => x.id === user.id);
		if (userModel && userModel.workPercentage) {
			return userModel.workPercentage;
		} else {
			return null;
		}
	}

	onUserWorkPercentageChange(event: number, user: ProjectUser) {
		const userModel = this.valueSync.find(x => x.id === user.id);
		if (userModel) {
			userModel.workPercentage = event;
		}
	}

	isUserSelected(user: ProjectUser, list: ProjectUser[]): boolean {
		return list.some(listUser => listUser.id === user.id);
	}
}

