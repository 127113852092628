
	import { Component, Vue, Watch } from 'vue-property-decorator';

	@Component
	export default class ProjectModalComboboxComponent extends Vue {
		searchValue = '';

		@Watch('searchValue', { immediate: true })
		onSearchValueChange(newVal: string): void {
			this.$emit('onSearchValueChange', newVal);
		}
	}
