

	import { PropType } from 'vue';
	import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';

	import { debounce } from 'lodash';
	import { minValue, numeric, required } from 'vuelidate/lib/validators';
	import { validationMixin } from 'vuelidate';
	import { AxiosError, AxiosResponse } from 'axios';

	import {
		Project,
		ProjectStatus,
		ProjectUser
	} from '@/modules/projects/types/entities';
	import { Actions } from '@/modules/projects/store/types/StoreTypes';
	import {
		Actions as ClientActions,
		Getters as ClientGetters
	} from '@/modules/entities/store/modules/clients/types/StoreTypes';
	import { Client, ClientVM } from '@/modules/entities/types/entities';
	import ProjectUserSelectComponent
		from '@/modules/projects/components/project-user-select/project-user-select.component.vue';
	import ProjectStatusSelectComponent
		from '@/modules/projects/components/project-status-select/project-status-select.component.vue';
	import ProjectDateSelectComponent
		from '@/modules/projects/components/project-date-select/project-date-select.component.vue';
	import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
	import { JsonResource } from '@/core/types/Entities';
	import ProjectModalComboboxComponent
		from '@/modules/projects/components/project-modal-combobox/project-modal-combobox.component.vue';

	const clients = namespace('entities/clients');
	const projects = namespace('projects');

	const workPercentageValidator = (value: number, vm: ProjectModalComponent, arrayKey: string): boolean => {
		let percentage = 0;
		switch (arrayKey) {
			case 'projectLeaders':
				vm.projectItem.projectLeaders.forEach((user: ProjectUser) => {
					if (user.workPercentage) {
						percentage += user.workPercentage;
					}
				});
				break;
			case 'estimators':
				vm.projectItem.estimators.forEach((user: ProjectUser) => {
					if (user.workPercentage) {
						percentage += user.workPercentage;
					}
				});
				break;
			case 'designers':
				vm.projectItem.designers.forEach((user: ProjectUser) => {
					if (user.workPercentage) {
						percentage += user.workPercentage;
					}
				});
				break;
			case 'drawers':
				vm.projectItem.drawers.forEach((user: ProjectUser) => {
					if (user.workPercentage) {
						percentage += user.workPercentage;
					}
				});
				break;
			default:
				return false;
		}
		return percentage === 100;
	};

	@Component({
		// @ts-ignore
		mixins: [validationMixin],
		components: {
			'user-group-select': ProjectUserSelectComponent,
			'project-status-select': ProjectStatusSelectComponent,
			'project-date-select': ProjectDateSelectComponent,
			'client-modal': () => import(/* webpackChunkName: "client-modal" */ '@/modules/entities/components/clients/client-modal.component.vue'),
			'project-modal-combobox': ProjectModalComboboxComponent
		},
		validations: {
			projectItem: {
				required,
				projectName: {},
				clients: {
					$each: {
						$trackBy: 'id',
						id: required
					}
				},
				projectLeaders: {
					$each: {
						$trackBy: 'id',
						workPercentage: {
							required,
							numeric,
							minValue: minValue(1),
							// @ts-ignore
							percentageSum: workPercentageValidator('projectLeaders')
						}
					}
				},
				estimators: {
					$each: {
						$trackBy: 'id',
						workPercentage: {
							required,
							numeric,
							minValue: minValue(1),
							// @ts-ignore
							percentageSum: workPercentageValidator('estimators')
						}
					}
				},
				designers: {
					$each: {
						$trackBy: 'id',
						workPercentage: {
							required,
							numeric,
							minValue: minValue(1),
							// @ts-ignore
							percentageSum: workPercentageValidator('designers')
						}
					}
				},
				drawers: {
					$each: {
						$trackBy: 'id',
						workPercentage: {
							required,
							numeric,
							minValue: minValue(1),
							// @ts-ignore
							percentageSum: workPercentageValidator('drawers')
						}
					}
				}
			}
		}
	})
	export default class ProjectModalComponent extends Vue {
		@projects.Action(Actions.CREATE_PROJECT) createProject!: (payload: Project) => Promise<AxiosResponse>;
		@projects.Action(Actions.EDIT_PROJECT_BY_ID) editProject!: (payload: Project) => Promise<void>;
		@clients.Action(ClientActions.FETCH_CLIENTS_BY_FILTER) fetchClientsByFilter!: () => void;
		@clients.Action(ClientActions.SET_CLIENTS_FILTER_NAME_QUERY) setNameQueryFilter!: (payload: string) => void;
		@clients.Action(ClientActions.GET_CLIENT_HIGHEST_PROJECT_NAME) getClientHighestProjectName!: (payload: number) => Promise<AxiosResponse<{ projectCount: string }>>;
		@clients.Action(ClientActions.CLEAR_FETCHED_CLIENTS) clearFetchedClients!: () => void;

		@clients.Getter(ClientGetters.GET_FETCHED_CLIENTS) clients!: Client[];
		@clients.Getter(ClientGetters.GET_LOADING) clientsLoading!: boolean;

		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
		@Prop({ type: Array as PropType<ProjectStatus[]>, default: () => [] }) statuses!: ProjectStatus[];
		@Prop({ type: Array as PropType<ProjectUser[]>, default: () => [] }) users!: ProjectUser[];
		@Prop({ type: Object as PropType<Project> }) projectItem!: Project;

		autocomplete!: google.maps.places.Autocomplete;
		citiesAutocomplete!: google.maps.places.Autocomplete;
		postalAutocomplete!: google.maps.places.Autocomplete;
		showClientModal = false;
		projectLeaders: ProjectUser[] = [];
		estimators: ProjectUser[] = [];
		drawers: ProjectUser[] = [];
		designers: ProjectUser[] = [];
		projectNamePlaceholder = 'Naam van het project';
		validationErrors: any = null;
		sfinxCenterLocation = { lat: 51.0218, lng: 3.4451 };
		addClientAtIndex = -1;

		get clientErrors(): string[] {
			const errors: string[] = [];
			this.$v.projectItem.client && !this.$v.projectItem.client.id.required && errors.push('Klant is vereist');
			return errors;
		}

		get projectLeadersErrors(): string[] {
			const errors: string[] = [];
			this.$v.projectItem.projectLeaders && this.$v.projectItem.projectLeaders.$each.$anyError && errors.push('Het totale werkpercentage mag niet hoger zijn dan 100.');
			return errors;
		}

		get estimatorsErrors(): string[] {
			const errors: string[] = [];
			this.$v.projectItem.estimators && this.$v.projectItem.estimators.$each.$anyError && errors.push('Het totale werkpercentage mag niet hoger zijn dan 100.');
			return errors;
		}

		get designersErrors(): string[] {
			const errors: string[] = [];
			this.$v.projectItem.designers && this.$v.projectItem.designers.$each.$anyError && errors.push('Het totale werkpercentage mag niet hoger zijn dan 100.');
			return errors;
		}

		get drawersErrors(): string[] {
			const errors: string[] = [];
			this.$v.projectItem.drawers && this.$v.projectItem.drawers.$each.$anyError && errors.push('Het totale werkpercentage mag niet hoger zijn dan 100.');
			return errors;
		}

		handleProjectFormSubmit(): void {
			if (this.projectItem.clients.length && this.projectItem.clients.some(x => x.id !== 0) && !this.projectItem.projectName) {
				this.projectItem.projectName = this.projectNamePlaceholder;
			}
			const projectCopy: Project = JSON.parse(JSON.stringify(this.projectItem));
			projectCopy.clients = projectCopy.clients.filter(x => x.id !== 0);
			if (this.projectItem.id && this.projectItem.id > 0) {
				this.editProject(projectCopy).then(() => {
					this.onEmitEditProject();
				}).catch((err: AxiosError) => {
					if (err.response && err.response.status === 422) {
						this.validationErrors = err.response.data.errors;
					} else {
						throw err;
					}
				});
			} else {
				this.createProject(projectCopy).then((res: AxiosResponse<Project>) => {
					this.onEmitCreateProject(res.data.id);
				}).catch((err: AxiosError) => {
					if (err.response && err.response.status === 422) {
						this.validationErrors = err.response.data.errors;
					} else {
						throw err;
					}
				});
			}
		}

		onClientChange(event?: Client): void {
			if (this.validationErrors && this.validationErrors.clients) {
				this.validationErrors.clients = null;
			}
			if (event && event.id) {
				if (!this.projectItem.projectName) {
					this.getClientHighestProjectName(event.id).then((res: AxiosResponse<{ projectCount: string }>) => {
						this.projectNamePlaceholder = `${ event.name } ${ res.data.projectCount }`;
						if (this.validationErrors && this.validationErrors.projectName) {
							this.validationErrors.projectName = null;
						}
					});
				}
			}
		}

		onAddClient(): void {
			this.projectItem.clients.push(new ClientVM());
		}

		onRemoveClient(index: number): void {
			if (index > 0 || this.projectItem.clients.length > 1) {
				this.projectItem.clients.splice(index, 1);
			}
		}

		onCreateNewClient(idx: number): void {
			this.showClientModal = true;
			this.addClientAtIndex = idx;
		}

		onCreateClient(client: Client): void {
			this.showClientModal = false;
			if (this.$v.projectItem.clients) {
				this.$v.projectItem.clients.$each.$iter[this.addClientAtIndex]!.$model = client;
			}
			this.onClientChange(client);
		}

		clientNameDebounce = debounce((value: string, showClientModal: boolean) => {
			if (value !== null && !showClientModal) {
				this.setNameQueryFilter(value);
			}
		}, 350);

		initAutocomplete(): void {
			this.autocomplete = new google.maps.places.Autocomplete(
				(this.$refs.streetInput as Vue).$el.querySelector('input') as HTMLInputElement,
				{ types: ["geocode"] }
			);
			this.citiesAutocomplete = new google.maps.places.Autocomplete(
				(this.$refs.cityInput as Vue).$el.querySelector('input') as HTMLInputElement,
				{ types: ["(cities)"] }
			);

			this.postalAutocomplete = new google.maps.places.Autocomplete(
				(this.$refs.postalInput as Vue).$el.querySelector('input') as HTMLInputElement,
				{ types: ['(regions)'] }
			);

			this.autocomplete.setFields(["address_component"]);
			this.citiesAutocomplete.setFields(["address_component"]);
			this.postalAutocomplete.setFields(["address_component"]);

			this.autocomplete.addListener("place_changed", this.fillInAddress);
			this.citiesAutocomplete.addListener('place_changed', this.fillInCity);
			this.postalAutocomplete.addListener('place_changed', this.fillInPostalCode);
		}

		fillInAddress(): void {
			const place = this.autocomplete.getPlace();

			for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
				const addressType = component.types[0];

				switch (addressType) {
					case 'route':
						this.projectItem.street = component.long_name;
						break;
					case 'postal_code':
						if (!this.projectItem.postalCode) this.projectItem.postalCode = component.short_name;
						break;
					case 'locality':
						if (!this.projectItem.postalCode) this.projectItem.city = component.long_name;
						break;
					case 'street_number':
						this.projectItem.streetNumber = component.short_name;
						break;
					default: break;
				}
			}
		}

		fillInCity(): void {
			const place = this.citiesAutocomplete.getPlace();

			for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
				const addressType = component.types[0];
				if (addressType === 'locality') this.projectItem.city = component.long_name;
			}
		}

		fillInPostalCode(): void {
			const place = this.postalAutocomplete.getPlace();

			for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
				const addressType = component.types[0];
				if (addressType === 'postal_code') this.projectItem.postalCode = component.short_name;
			}
		}

		created(): void {
			this.projectLeaders = JSON.parse(JSON.stringify(this.users));
			this.estimators = JSON.parse(JSON.stringify(this.users));
			this.designers = JSON.parse(JSON.stringify(this.users));
			this.drawers = JSON.parse(JSON.stringify(this.users));
			this.projectLeaders.forEach(user => {
				if (!user.workPercentage) {
					Vue.set(user, 'workPercentage', 0);
				}
			});
			this.estimators.forEach(user => {
				if (!user.workPercentage) {
					Vue.set(user, 'workPercentage', 0);
				}
			});
			this.designers.forEach(user => {
				if (!user.workPercentage) {
					Vue.set(user, 'workPercentage', 0);
				}
			});
			this.drawers.forEach(user => {
				if (!user.workPercentage) {
					Vue.set(user, 'workPercentage', 0);
				}
			});
			if (this.projectItem.projectName) {
				this.projectNamePlaceholder = this.projectItem.projectName;
			}
		}

		mounted(): void {
			this.initAutocomplete();
		}

		destroyed(): void {
			this.clearFetchedClients();
		}

		@Watch('projectItem.projectName')
		onProjectNameValueChange(): void {
			if (this.validationErrors && this.validationErrors.projectName) {
				this.validationErrors.projectName = null;
			}
		}

		onClientNameSearchValueChange(val: string): void {
			this.clientNameDebounce(val, this.showClientModal);
		}

		@Emit('onCreateProject')
		onEmitCreateProject(projectId: number): number {
			return projectId;
		}

		@Emit('onEditProject')
		onEmitEditProject(): void {
			return;
		}
	}
